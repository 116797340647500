html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

nav {
  a {
    text-decoration: none;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c3c3c3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.MuiDrawer-paper {
  scrollbar-width: thin;
  scrollbar-color: #c3c3c3 #ffffff;
}

/* Chrome, Edge, and Safari */
.MuiDrawer-paper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  background: #ffffff;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 8px;
  border: 1px solid #ffffff;
}
